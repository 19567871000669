<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      菜单管理
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  data () {
    return {
      sdsd: 'sd'
    }
  }

}
</script>

<style>

</style>
